<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <el-select
                            v-model="projectNos"
                            filterable
                            clearable
                            multiple
                            collapse-tags
                            placeholder="请选择项目"
                        >
                            <el-option
                                v-for="item in projectList"
                                :key="item.projectNo"
                                :label="item.projectName"
                                :value="item.projectNo"
                            />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">投放渠道</span>
                    <span class="condition_content">
                        <el-cascader
                            v-model="providerNos"
                            :options="providerCascader"
                            placeholder="投放渠道"
                            collapse-tags
                            filterable
                            clearable
                            :props="{ expandTrigger: 'hover', multiple: true }"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入内容"
                            clearable
                            v-model.trim="queryTypeValue"
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        >
                            <el-select class="query_type" v-model="queryType" slot="prepend" placeholder="请选择">
                                <el-option label="订单号" value="orderNo" />
                                <el-option label="联系方式" value="contact" />
                                <el-option label="快递单号" value="expressNo" />
                                <el-option label="收货人手机号" value="receiveMobile" />
                            </el-select>
                        </el-input>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">发货状态</span>
                    <span class="condition_content">
                        <el-select v-model="status" placeholder="请选择" clearable>
                            <el-option
                                v-for="item in expressStatusList"
                                :key="item.val"
                                :value="item.val"
                                :label="item.name"
                            />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">更新时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="modTimes"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:59']"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">订单时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="commitTimes"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:59']"
                        />
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button @click="onPaginationChange('page', 1)" type="primary">查询</el-button>
                <el-button
                    v-if="P(1010603)"
                    class="float_left_btn"
                    @click="onBatchDelExpress"
                    :disabled="delOrderNos.length === 0"
                    type="danger"
                    plain
                >
                    批量删除 ({{ delOrderNos.length }})
                </el-button>

                <el-button class="float_left_btn" v-if="P(1010601)" @click="onShowEditExpress(null)">
                    新建物流
                </el-button>
                <el-button class="float_left_btn" v-if="P(1010602)" @click="onShowBatchUploadExpress">
                    批量导入
                </el-button>

                <div class="float_left_btn export" v-if="P(1010604)">
                    <ExportFile
                        host="hunter"
                        path="/xbTrackFormRecord/express/export"
                        @before-export="onBeforeExport"
                    />
                    <el-tooltip effect="dark" content="最多导出5000条数据" placement="top">
                        <i class="el-icon-question" />
                    </el-tooltip>
                </div>
            </div>
        </div>
        <el-table :data="tableData" @selection-change="onSelectionChange" border stripe>
            <el-table-column align="center" type="selection" width="55" />
            <el-table-column align="center" prop="id" width="80" label="ID" />
            <el-table-column header-align="center" prop="providerNo" label="投放渠道">
                <template slot-scope="scope">
                    <span v-if="scope.row.formInfo">{{ providerMap[scope.row.formInfo.providerNo] || '-' }}</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" prop="orderNo" label="订单号" />
            <el-table-column header-align="center" prop="telNo" width="180" label="收货人信息">
                <template slot-scope="scope">
                    <div>
                        <div>姓名: {{ scope.row.receiveName }}</div>
                        <div>手机号: {{ scope.row.receiveMobile }}</div>
                        <div>收货地址: {{ scope.row.receiveAddress }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column header-align="center" prop="companyName" label="快递公司" />
            <el-table-column header-align="center" prop="expressNo" label="快递单号" />

            <el-table-column header-align="center" label="表单详情" min-width="200">
                <template slot-scope="scope">
                    <div v-if="scope.row.formInfo">
                        <div class="form_detail">
                            表单时间: <span>{{ scope.row.formInfo.commitTime }}</span>
                        </div>
                        <div class="form_detail" v-if="scope.row.formInfo.formDetailListMap">
                            <div v-for="(item, index) in scope.row.formInfo.formDetailListMap" :key="index">
                                {{ item.name }}: <span>{{ item.value }}</span>
                            </div>
                        </div>
                        <div v-else-if="scope.row.formInfo.consultContent">
                            {{ scope.row.consultContent }}
                        </div>
                    </div>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="sentTime" width="96" label="发货时间" />
            <el-table-column align="center" prop="modTime" width="96" label="更新时间" />
            <el-table-column align="center" width="100" label="状态">
                <template slot-scope="scope">
                    <el-tag effect="dark" :type="expressStatusMap[scope.row.status]['tag']" v-if="scope.row.status">
                        {{ expressStatusMap[scope.row.status]['name'] }}
                    </el-tag>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="64" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-link v-if="P(1010601)" @click="onShowEditExpress(scope.row)" type="primary">编辑</el-link>
                    <el-popconfirm v-if="P(1010603)" title="确定删除吗？" @confirm="onDelExpress(scope.row)">
                        <el-link slot="reference" type="danger">删除</el-link>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            :page-sizes="[10, 20, 50, 100]"
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        />

        <EditExpress :isShow="isShowEditExpress" :activeItem="activeItem" @onClose="onCloseEditExpress" />

        <BatchUploadExpress :isShow="isShowBatchUploadExpress" @onClose="onCloseBatchUploadExpress" />
    </div>
</template>

<script>
import EditExpress from './components/express/EditExpress.vue'
import BatchUploadExpress from './components/express/BatchUploadExpress.vue'
import { contactType } from '@/js/config/opportunit.js'
import { filterQueryEmpty, isEmptyArray, listObjectField } from '@/js/utils.js'
import { getFormExpressList, xbTrackFormRecordList, delFormExpress } from '@/js/api/advertApi.js'
import { getProviderCategoryData } from '@/js/api/advertApi.js'
import { expressStatusList, expressStatusMap } from '@/js/config/advert.js'
import { mapGetters } from 'vuex'

export default {
    computed: { ...mapGetters(['projectList']) },
    components: { EditExpress, BatchUploadExpress },
    data() {
        return {
            expressStatusMap,
            projectNos: '',
            providerCascader: [],
            providerNos: [],
            providerMap: null,
            contactType,
            queryTypeValue: '',
            queryType: 'contact',
            expressStatusList,
            status: '',
            modTimes: [],
            commitTimes: [],

            delOrderNos: [],
            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            activeItem: null,
            isShowEditExpress: false,
            isShowBatchUploadExpress: false,
        }
    },
    watch: {
        projectList: function () {
            this.onReset()
        },
    },
    created() {
        this.getFormExpressListApi()
        this.getProviderCategoryDataApi()
    },
    methods: {
        onReset() {
            this.projectNos = ''
            this.providerNos = []
            this.queryTypeValue = ''
            this.status = ''
            this.modTimes = []
            this.commitTimes = []
            this.onPaginationChange('page', 1)
        },

        // 单个删除
        onDelExpress(item) {
            delFormExpress({ expressIds: [item.id] }).then(() => {
                this.$message.success('删除成功')
                this.getFormExpressListApi()
            })
        },
        // 批量删除
        onBatchDelExpress() {
            this.$confirm(`共选中 ${this.delOrderNos.length} 条快递信息，是否继续删除?`, '批量删除快递信息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    delFormExpress({ expressIds: this.delOrderNos }).then(() => {
                        this.$message.success('删除成功')
                        this.getFormExpressListApi()
                    })
                })
                .catch(() => {})
        },

        onSelectionChange(vals) {
            this.delOrderNos = vals.map((i) => i.id)
        },

        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.getFormExpressListApi)
        },

        // 编辑快递信息
        onShowEditExpress(item) {
            this.activeItem = item
            this.isShowEditExpress = true
        },
        onCloseEditExpress(state) {
            this.activeItem = null
            this.isShowEditExpress = false
            if (state) this.getFormExpressListApi()
        },

        // 批量创建
        onShowBatchUploadExpress() {
            this.isShowBatchUploadExpress = true
        },
        onCloseBatchUploadExpress(state) {
            this.isShowBatchUploadExpress = false
            if (state) this.getFormExpressListApi()
        },

        onBeforeExport(cb) {
            cb(this.getParams())
        },

        getParams() {
            let params = { status: this.status, formDetailSearch: this.formDetailSearch }
            params.providerNos = !isEmptyArray(this.providerNos) ? this.providerNos.map((item) => item[1]) : null
            if (this.queryType === 'orderNo' && this.queryTypeValue) {
                params.orderNos = [this.queryTypeValue]
            } else {
                params[this.queryType] = this.queryTypeValue
            }
            if (!isEmptyArray(this.projectNos)) {
                params.projectNos = this.projectNos
            } else if (this.projectList.length !== 0) {
                params.projectNos = this.projectList.map((i) => i.projectNo)
            } else {
                return
            }
            params.modTimes = !isEmptyArray(this.modTimes) ? this.modTimes : ''
            params.commitTimes = !isEmptyArray(this.commitTimes) ? this.commitTimes : ''
            params = filterQueryEmpty(params)
            return params
        },

        async getFormExpressListApi() {
            let params = {
                orderBy: '-id',
                page: this.page,
                size: this.pageSize,
                showPage: true,
                ...this.getParams(),
            }
            params = filterQueryEmpty(params)

            const res1 = await getFormExpressList(params)
            if (res1.data.list.length !== 0) {
                const _orderNos = listObjectField({ list: res1.data.list, key: 'orderNo' })
                const res2 = await xbTrackFormRecordList({ orderNos: _orderNos })
                const _map = {}
                res2.data.forEach((item) => {
                    if (item.formDetail) {
                        item.formDetailListMap = JSON.parse(item.formDetail)
                    }
                    _map[item.orderNo] = item
                })
                res1.data.list.forEach((i) => {
                    i.formInfo = _map[i.orderNo]
                })
            }
            this.tableData = res1.data.list
            this.total = res1.data.total
            this.page = res1.data.pageNum
        },
        getProviderCategoryDataApi() {
            getProviderCategoryData().then((res) => {
                this.providerList = res.providerList
                this.providerMap = res.providerMap
                this.providerCascader = res.providerCascader
            })
        },
    },
}
</script>

<style lang="less">
.export {
    display: flex;
    align-items: center;
    i {
        margin-left: 8px;
        font-size: 18px;
        color: #666;
        cursor: pointer;
    }
}
.form_detail {
    span {
        font-weight: 600;
        color: #000000;
    }
}
</style>
