export const expressStatusList = [
    { name: '信息不全', val: 'UNCOLLECT' },
    { name: '未发货', val: 'UNSEND' },
    { name: '已发货', val: 'SENT' },
]

export const expressStatusMap = {
    UNCOLLECT: { name: '信息不全', tag: 'warning' },
    UNSEND: { name: '未发货', tag: 'info' },
    SENT: { name: '已发货', tag: 'success' },
}
